@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Avenir-Book.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/avenir-medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/Avenir-Roman.woff') format('woff'), /* Pretty Modern Browsers */
  url('./fonts/Avenir-Book.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('./fonts/Avenir-Book.svg#svgFontName') format('svg'); /* Legacy iOS */
}

body #root {
  margin-top: -1rem;
  overflow-y: hidden;
 
}

*, body, html {
  margin: 0;
  padding: 0;
  font-family: Avenir !important;
 
}

.fa, .far, .fas {
  font-family: Font Awesome\ 5 Free !important;
}

.fab {
  font-family: Font Awesome\ 5 Brands !important;
}

@font-face {
  font-family: 'Avenir heavy';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/Avenir-Heavy.eot');
  src: url('./fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .bold-IE {
    font-family: Avenir heavy !important;
    font-weight: 800;
  }
  .company-name-label.bold-IE {
    font-size: 16px;
  }
  .title-col.bold-IE {
    font-size: 16px;
  }
}
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background:rgba(255, 255, 255, 0.1);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
