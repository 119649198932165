/**************scroll bar ***************/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  [tabindex='-1'] {
    height: 100vh;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.VideoController {
  position: absolute;
  width: 100%;
}

.VideoController .MainContainer {
  display: flex;
  justify-content: space-between;
}

.VideoController .InputSearch {
  order: 1;
  flex-grow: 5;
}

.FixedpositionSearch {
  padding: 0 1px;
}

.VideoController .Container2 {
  display: flex;
  flex-direction: column;
}

.Seccion2 {
  order: 1;
}

.VideoController .Seccion1 {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  position: relative;
}

.VideoController .VideoList {
  order: 2;
}

a {
  color: #676F7E !important;
  font: Medium 14px/24px Avenir;
}

.linkVideos {
  margin: 10px 55px;
  display: inline-block;
  font-size: 14px !important;
}

.linkVideos img {
  margin: 0 10px;
}

.VideoContainer, .VideoContainer>div {
  min-width: 480px;
  width: 100%;
  /* min-height: 220px; */
  overflow-y: hidden;
  background-color: black;
  padding: 5px;
}

.cursorPointer {
  cursor: pointer;
}

.VideoContainer, .VideoContainer>div>iframe {
  margin: auto 0px;
  width: 100% !important;
}

.spinner {
  color: #212529;
  font-size: 4em;
  display: block;
  margin: auto;
  position: absolute;
}

.containerCentral {
  max-width: 1400px;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width:1124px) {
  .listVideos .bodyList {
    height: 550px;
  }
  .TitleHeader {
    padding-left: 6%;
  }
  .VideoController .Seccion1 {
    height: 670px;
  }
  .VideoController .InputSearch, .VideoController .VideoList {
    width: 100%;
    padding: 0 25px;
  }
  .VideoController {
    top: 30px;
  }
  .fixedBackground {
    background-size: 100%;
  }
  .VideoController .MainContainer {
    flex-direction: row;
    position: relative;
    height: 670px;
  }
  .FixedpositionSearch {
    position: absolute;
    bottom: 0;
    left: 22%;
  }
  .spinner {
    top: 45%;
    right: 23%;
  }
}

.modal-header {
  display: none !important;
}

@media (max-width:672px) {
  .TitleHeader {
    padding-left: 10px;
  }
  .FixedpositionSearch>div {
    float: none !important;
    display: block !important;
    width: auto;
    position: static !important;
    left: 10px;
    bottom: 1px;
    width: 295px !important;
    margin: 15px auto !important;
    padding: 0 !important;
  }
  .VideoController .Seccion1 {
    height: 300px !important;
  }
  .linkVideos {
    text-align: center;
  }
  .VideoContainer, .VideoContainer>div {
    min-width: 250px !important;
    width: 100% !important;
    overflow-y: hidden;
  }
  .VideoController .MainContainer {
    flex-direction: column;
    height: 250px !important;
  }
}

@media (max-width:1124px) {
  .TitleHeader {
    padding-left: 10px;
  }
  .VideoController .MainContainer {
    flex-direction: column;
    height: 250px;
    position: relative;
  }
  .VideoController .Seccion1 {
    height: 300px;
  }
  .linkVideos {
    text-align: center;
  }
  .FixedpositionSearch {
    position: relative;
    bottom: 0px;
    left: 0;
  }
  .fixedBackground {
    background-size: 100% 187px;
  }
  .FixedpositionSearch>div:first-child {
    float: right;
    display: inline;
    width: auto;
    padding: 0 15px;
  }
  .FixedpositionSearch>div {
    float: none !important;
    display: block !important;
    width: auto;
    position: static !important;
    left: 10px;
    bottom: 1px;
    width: 295px !important;
    margin: 15px auto !important;
    padding: 0 !important;
  }
  .VideoList {
    margin: 10px;
  }
  .logo {
    display: none;
  }
  .VideoController {
    top: 0 !important;
  }
  .spinner {
    top: 245%;
    right: 45%;
  }
}

#selectFilter option {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

@media screen and (max-width: 270px) {
  .product-select {
    flex-direction: column;
  }
  .filter-select {
    flex-direction: column;
  }
  #title_header .title-center {
    margin: '12px 0px 10px 24px';
  }
}

@media screen and (max-width: 320px) {
  .InputSearch {
    width: 210px;
  }
  .linkVideos {
    width: 210px;
  }
}

@media screen and (max-width: 310px) {
  .product-span {
    margin-top: 7px;
  }
}

@media screen and (min-width: 1125px) {
  #video_header {
    flex-wrap: inherit
  }
}