.modal {
    width: 100% !important;
}

.QuickQuoteModal {
    width: 80% !important;
    max-width: 670px !important;
}

.RFQModal {
    width: 95% !important;
    max-width: 1086px !important;
}

.PremiumVendor {
    width: 100% !important;
    max-width: 90% !important;
    padding: 0 2%;
    margin: 0.5rem auto !important;
}

@media (min-width: 576px) {
    .PremiumVendor {
        width: 100%!important;
        max-width: 90%!important;
    }
}

.modal-header {
    display: inline !important;
    border-bottom: none !important;
    text-align: center;
}

.modal-content {
    border-radius: 0% !important;
}

.modal-header .close {
    transform: translate(0px,-60%);
}

.modal-body {
    padding: 0% !important;
    overflow-x: hidden;
    -ms-overflow-x: hidden;
}

.fade {
    opacity: unset;
}

.modalTitle {
    font-weight: bolder;
}